* {
  box-sizing: border-box;
}

:root {
  --primary-color-lightest: #66617C;
  --primary-color-light: #4A455E;
  --primary-color: #2A2738;
  --primary-color-dark: #1F1A34;
  --primary-color-darkest: #140F29;
  
  --correct-light: #48bf53;
  --correct: #11823b;
  --correct-dark: #004d25;

  --incorrect-light: #fe5757;
  --incorrect: #fe2e2e;
  --incorrect-dark: #cb2424;
}

/* Elements */
body {
  font-family: 'Noto Sans JP', sans-serif;
  color: white;
  margin: 0;
  padding: 0;
  background-color: var(--primary-color);
  height: 100vh;
}

button {
  font-family: 'Noto Sans JP', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

tbody tr:nth-child(odd) {
  background-color: var(--primary-color-light);
}

tbody tr:nth-child(even) {
  background-color: var(--primary-color-lightest);
}

td, th {
  border: 2px solid white;
  padding-left: 0.2rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

/* Classes */
.root{
  height: 100%;
}

.body {
  height: 100%;
}

.image {
  max-width: 100%;
  max-height: 90vh;
  margin: 1rem auto;
}

.rounded-corners {
  border-radius: 1rem;
}

.container {
  margin: 0 auto;
  width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  height: calc(100% - 4rem);
  font-size: 1.1rem;
}

.center-container {
  display: flex;
  justify-content: center;
  margin-inline: 4rem;
}

.question-container {
  text-align: center;
}

.question {
  font-size: 7rem;
  margin: 0;
  margin-bottom: 2rem;
}

.toolTip {
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-underline-offset: 0.4rem;
}

.answer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.answer-button {
  font-size: 5rem;
  width: 25%;
  padding-bottom: 10px;
}

.content {
  display: flex;
}

.section-nav-aside {
  width: 250px;
  margin-top: 2.5rem;
  flex-shrink: 0;
}

.section-nav {
  width: 250px;
  position: fixed;
  border-right: 3px solid var(--primary-color-light)
}

.section-nav-item {
  cursor: pointer;
}

.section-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}

.section-content {
  margin: 0 2.5rem;
  display: flex;
  flex-direction: column;
}

.content-section {
  border-top: 2px solid var(--primary-color-light);
  padding-top: 1rem;
  margin-bottom: 2rem;
  scroll-margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
}

.column-kanji {
  width: 25%;
}

.column-english {
  width: 45%;
}

.kana-table {
  max-width: 40rem;
  margin-inline: auto;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.kana-table th {
  background-color: var(--primary-color-light);
}

.kana-table td, .kana-table th {
  text-align: center;
  font-size: 2em;
}

.kana-table .syllable {
  font-size: 0.5em;
}

@media screen and (max-width: 1150px) {
  .container {
    width: 900px;
  }
}

@media screen and (max-width: 950px) {
  .container {
    width: 100vw;
    padding: 0;
    font-size: 1rem;
  }

  .center-container {
    flex-direction: column;
  }
  
  .section-nav-aside {
    display: none;
  }

  .kana-table {
    font-size: 0.8rem;
  }
}

#root {
  height: 100%;
}