.kana-quiz-container {
  margin-inline: 2rem;
  margin-bottom: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kana-quiz-sub-container {
  display: flex;
  justify-content: space-between;
  height: 100%
}

.kana-question-container {
  text-align: center;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kana-question-history {
  overflow: overlay;

  scrollbar-width: thin;
  scrollbar-color: red, blue;
}

.kana-question-history.vertical::-webkit-scrollbar {
  width: 8px;
}

.kana-question-history.horizontal::-webkit-scrollbar {
  height: 8px;
}

.kana-question-history::-webkit-scrollbar-thumb {
  background-color: var(--primary-color-dark);
  transition: background-color 0.2s linear;
  border-radius: 4px;
}

.kana-question-history::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-color-light);
  transition: background-color 0.2s linear;
}

.kana-question-history.vertical {
  width: 14rem;
  border-left: 3px solid var(--primary-color-light);
  margin-block: 2rem;
  padding-left: 1rem;
}

.kana-question-history.vertical .kana-answer-history-item {
  margin-bottom: 1rem;
}

.kana-question-history.horizontal .kana-answer-history-item {
  margin-right: 1rem;
}

.kana-question-history.horizontal {
  display: flex;
  height: 7rem;
  align-items: center;
  border-top: 3px solid var(--primary-color-light);
}

.kana-answer-history-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.5rem;
  border: 0.2rem solid white;
  border-radius: 0.5rem;
  height: 3rem;
  max-width: 11rem;
  min-width: 11rem;
  color: black;
}

.kana-quiz-options-container {
  display: inline-grid;
  grid-template-columns: auto auto;
  justify-content: center;
  font-size: 1rem;
}

.kana-quiz-options-container label {
  margin: 1rem;
  display: flex;
  align-items: center;
}

.kana-quiz-options-container input {
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0.5rem;
}

.kana-answer-history-item.correct {
  border: 0.2rem solid var(--correct);
  background-color: var(--correct-light);
}

.kana-answer-history-item.correct .kana-answer-history-item-text {
  border-left: 0.2rem solid var(--correct);
}

.kana-answer-history-item.correct .kana-answer-history-item-text:first-child {
  border-left: 0;
}

.kana-answer-history-item.incorrect {
  border: 0.2rem solid var(--incorrect);
  background-color: var(--incorrect-light);
}

.kana-answer-history-item.incorrect .kana-answer-history-item-text {
  border-left: 0.2rem solid var(--incorrect);
}

.kana-answer-history-item.incorrect .kana-answer-history-item-text:first-child {
  border-left: 0;
}


.kana-answer-history-item-text {
  line-height: 2.5rem;
  width: 100%;
  text-align: center;
  margin: 0;
}

.kana-answer-input {
  width: 12rem;
  font-size: 4rem;
  text-align: center;
  padding: 1rem;
  margin-bottom: 4rem;
  border-radius: 1rem;
}

.kana-answer-input.correct {
  box-shadow: 0 0 1rem 0.5rem var(--correct-light);
}

.kana-answer-input.correct:disabled {
  background-color: var(--correct-light);
  border-color: var(--correct);
  color: black;
  border-style: solid;
}

.kana-answer-input.incorrect {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 1rem 0.5rem red;
}

.kana-answer-input.incorrect:disabled {
  background-color: var(--incorrect-light);
  border-color: var(--incorrect);
  color: black;
  border-style: solid;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}

@media screen and (max-width: 950px) {
  .kana-quiz-options-container {
    display: inline;
    margin: auto;
  }
}