:root {
  --navbar-height: 4rem;
}

.nav-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.navbar-ghost {
  height: var(--navbar-height);
}

.navbar {
  background-color: var(--primary-color-darkest);
  color: white;
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  gap: 2rem;
  padding: 0rem 1rem;
  width: 100%;
  box-shadow: 0 0.1rem 0.2rem 0.2rem rgba(9, 9, 9, 0.23);
  height: var(--navbar-height);
  position: fixed;
  top: 0;
  z-index: 100;

}

.navbar i {
  color: white;
  font-size: 1.2rem;
}

.site-title {
  font-size: 2rem;
  font-weight: 700;
}

.navbar .navbar-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.navbar .navbar-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.navbar a span {
  color: inherit;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0.5rem;
  transition: border-bottom 0.2s ease-in-out;
}

.navbar a span::after {
  content: attr(data-text);
  font-weight: 700;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.nav-logo-container .logo {
  border-radius: 50%;
  margin-right: 0.4rem;
}

.navbar .navbar-item.active {
  font-weight: 700;
  border-bottom: 3px solid white;
  transition: border-bottom 0.2s ease-in-out;
}

.nav-logo-container {
  display: flex;
  align-items: center;
}

.navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 0.5rem;
  height: 100%;
}

.navbar a i {
  margin: 0;
  transition: 400ms ease;
}

.navbar a i.open {
  transform: rotate( -180deg );            
  transition: transform 400ms ease;
}




.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  display: none;
}




.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color-light);
  top: var(--navbar-height);
  overflow: hidden;
  min-width: 13rem;
  padding-bottom: 0.1rem;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0rem 0.5rem 0.2rem rgba(9, 9, 9, 0.5);
}

.dropdown ul {
  display: flex;
  flex-direction: column;
}

.dropdown .dropdown-item {
  height: 100%;
  display: flex;
  border-left: 3px solid transparent;
  transition: border-left 0.2s ease-in-out;
}

.dropdown .dropdown-item.active {
  font-weight: 700;
  border-left: 3px solid white;
  transition: border-left 0.2s ease-in-out;
}




@media (hover: hover) {
  .navbar .navbar-item:hover {
    border-bottom: 3px solid rgba(255, 255, 255, 0.5);
    transition: border-bottom 0.2s ease-in-out;
  }

  .dropdown .dropdown-item:hover {
    border-left: 3px solid rgba(255, 255, 255, 0.5);
    transition: border-left 0.2s ease-in-out;
  }

  .sidenav .menu .item a:hover {
    background-color: var(--primary-color-dark);
    transition: background-color 0.2s ease-in-out;
  }

  .hamburger:hover {
    background-color: var(--primary-color-lightest);
    transition: background-color 0.2s ease-in-out;
  }
}

@media screen and (max-width: 796px) {
  .hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 auto;
  }

  .navbar ul {
    display: none;
  }
}


.sidenav {
  background-color: var(--primary-color-darkest);
  width: 100%;
  height: calc(100% - 4rem);
  position: fixed;
  top: var(--navbar-height);
  right: 0;
  overflow-y: auto;
  transition: right 350ms ease-in-out;
}

.sidenav::-webkit-scrollbar {
  display:none;
}

.sidenav.hide {
  right: -100%;
  transition: right 350ms ease-in-out;
}

.sidenav .menu {
  width: 100%;
}

.sidenav .menu .item {
  position: relative;
  cursor: pointer;
}

.sidenav .menu .item a {
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  padding: 5px 30px;
  line-height: 60px;
  transition: background-color 0.2s ease-in-out;
  border-left: 3px solid transparent;
}

.sidenav .menu .item .sub-menu {
  background-color: var(--primary-color);
}

.sidenav .menu .item .sub-menu a {
  padding-left: 60px;
}

.sidenav .menu .item a.active {
  font-weight: 700;
  border-left: 3px solid white;
}

.sidenav .menu .item a span {
  color: inherit;
  text-decoration: none;
  text-align: center;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.sidenav .menu .item a span::after {
  content: attr(data-text);
  font-weight: 700;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  user-select: none;
  pointer-events: none;
}

.sidenav i {
  position: absolute;
  right: 0;
  margin: 20px;
  transition: 200ms ease;
}

.sidenav i.open {
  transform: rotate( -180deg );            
  transition: transform 200ms ease;
}